

// Import Bootstrap SCSS
// @import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';










@import '../../node_modules/bootstrap/scss/bootstrap.scss';



button.danger-radio-red{padding: 0;}
button.danger-radio-dark{padding: 0;}
.radio-timg-sec{display: flex;justify-content: space-between;align-items: center;padding: 1rem;}
.radiotext{font-size: 1rem;font-weight: 500;color: #000;}
.radio-timg-sec svg{height:37px;}
.text-blue{color: #000000;}
.text-light{color: #0058A3!important;}
.radiotext.svg-light svg path{fill:#0058A3!important;}
.radiotext.svg-blue svg path{fill:#000000!important;}
.text-yellow{color: #FFDB00;}
.btnradio, .btn-radio{border-radius: 10px !important;}

.danger-radio-dark .radio-timg-sec svg{fill: #000 !important; filter: invert(0%) sepia(0%) saturate(0%);}
.MuiSlider-markLabel{color: rgb(255,255,255)!important;}

.step-main .MuiStepLabel-iconContainer.Mui-active, .step-main .MuiStepLabel-iconContainer.Mui-disabled{padding-right:0!important;}

.step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.5em!important;
  height: 1.5em!important;}
  .step-main .MuiStepLabel-iconContainer.Mui-disabled svg circle{fill: #000;}
  .step-main .MuiStepLabel-iconContainer svg text{font-size: 0.8rem; font-weight: bold;}

  .step-main .MuiStepLabel-iconContainer.Mui-completed{
    padding-right:0!important;
  } 
  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 1.5em!important;
    height: 1.5em!important;}
    .map-secstep{margin: 0;width:100%; height:95%; }

.big-title{font-size:1.875rem;line-height: 2.5rem; font-weight: 600;margin:0 auto 20px auto;white-space:preserve;text-align: center;color:#FFFFFF;}
.big-title1{font-size:1.875rem;line-height:  2.5rem; font-weight: 600;margin:20px auto 0px auto;text-align: center;color:#FFFFFF;}

.btn-right{display:flex; justify-content: space-between;align-items: center;}

    .button-wrapper .btn{margin-top: 0;}
    .inputbox{border-radius: 6px;border:2px solid #000;}
    .inputbox:focus-visible{box-shadow: none;
      outline: 0;}
      .first-button-wrapper{display: flex;justify-content: space-between;}
      .voter-box-grid-main{max-width:52rem;margin: 0 auto 20px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.voter-box-grid{display: flex;flex-direction: row;gap:1rem;}
.voter-box{width: 25%;display: flex;flex-direction: row;}
.voter-box-grid-main2{max-width:100%;margin: 0 auto 20px;display: flex;justify-content: end;align-items: center;flex-direction: column;}
.voter-box-grid2{display: flex;flex-direction: row;gap:1rem;width: 100%;}
.voter-box2{width: 15%;display: flex;flex-direction: row;}
.voter-box-btn{ box-shadow:0 10px 15px rgb(16, 67, 123,0.1);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: center;padding: 10px;}
// .voter-box-btn svg{  filter: invert(0%) sepia(0%) saturate(0%);}
.voter-box-btn.voter-box-btn-red svg{ fill: none !important;filter: none;}
// .voter-box-btn.voter-box-btn-red svg path{stroke:#000;}
// .voter-box-text.text-danger{color:#000 !important;}
.voter-box-grid-main3{max-width: 100%;display: flex;justify-content: end;}


    .voter-box-btn-red, .voter-box-btn-red2{border:2px dashed #FFDB00;}
   .voter-box-btn.voter-box-btn-black, .voter-box-btn-black, .voter-box-btn-black2, .voter-box-btn2.voter-box-btn-black2{border:2px dashed #fff!important;}
    
    .voter-box-text, .voter-box-text2{font-size: 0.8rem;font-weight: 500; line-height: 1rem;display: flex;flex-direction: column;margin: 10px 0 0 0;}
    .voter-box-text.text-dark, .voter-box-text2.text-dark{color:#FFFFFF!important;}
    .voter-box-btn.voter-box-btn-red.border-danger, .voter-box-btn2.voter-box-btn-red2.border-danger{border:2px dashed #fff!important;background: #013b6c;}

    .voter-box-btn2{ box-shadow:0 10px 15px rgb(16, 67, 123,0.1);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: flex-start;padding: 10px;}
    .fase-svg{height: 120px;width: auto;}
    .voter-box-btn.voter-box-btn-red .voter-box-text.text-danger{color:#fff!important;}
    .voter-box-btn2.voter-box-btn-red2 .voter-box-text2.text-danger{color:#fff!important;}

    // .voter-box-btn2 svg{ filter: invert(0%) sepia(0%) saturate(0%);}
    // .voter-box-btn2.voter-box-btn-red2 svg{ fill: none !important;filter: none;}
    .voter-box-btn.voter-box-btn-red.border-danger svg path{stroke: rgb(255,255,255)!important;}
    // .voter-box-btn2.voter-box-btn-red2.border-danger svg path{stroke:  rgb(0,88,163)!important; fill:rgb(255,219,0)!important;}
    

    .bonne-sec{max-width: 49rem;margin: 0 auto;}
    .bonne-sec .two-col-row{display:flex;flex-direction: row;gap:1rem;}
    .bonne-sec .two-col{display:flex;flex-direction: column;width:49%;margin-bottom:15px;}
    .bonne-sec .bonne-btn-sec{margin: 20px 0;text-align: right;display: flex;justify-content: flex-end;}
    .bonne-sec .label-col{display: flex;flex-direction: column;text-align: center;}
    .bonne-sec .label-col-not-center{display: flex;flex-direction: column;justify-content: flex-start;}

    .question-btn{padding:0px;background: transparent;margin: 0 0 0 5px;display: flex;}
    .question-btn img{width: 24px;}
   .question-modal{}
   .question-modal .que-header{display: flex;justify-content: flex-end;}
   .question-modal .que-header .close-icon{width:25px;height: 25px;border-radius: 100px;border:0px;background:#000;display: flex;
    align-items: center; justify-content: center;}
    .question-modal .que-header .close-icon i.fas.text-light{color: #FFDB00!important ;} 
   .question-modal .content-wrapper{color:#000;font-size: 0.9rem;line-height: 1.3rem;max-height: 500px;
    overflow: hidden;overflow-y: scroll;}
   .question-modal .content-wrapper ul.qm-list{color:#000;margin:0 0 15px 20px; padding: 0;}
   .question-modal .content-wrapper ul.qm-list li{color:#000;font-size: 0.9rem;}
   .question-modal .content-wrapper ul.qm-list ul{color:#000;margin:0 0 10px 20px; padding: 0;}

   .contacte-form .label-text{font-size: 0.9rem;line-height: 1.3rem;font-weight: 600;color:#000;max-width: 338px;margin:15px auto;display: flex;text-align: center;}

   .contacte-form{max-width: 49rem;margin: 0 auto;gap: 1rem;display: flex;flex-direction: column;}
   .contacte-form .two-col-row{display:flex;flex-direction: row;gap:1rem;}
   .contacte-form .two-col{display:flex;flex-direction: column;width:49%;}
   .contacte-form .one-col{display:flex;flex-direction: column;width:100%;}
   .contacte-form .label-col{display: flex;flex-direction: column;text-align: center;}
   .contacte-form .label-col .title{font-size: 1.5rem;line-height: 2rem; font-weight: 600;}
   .droup-select{}
   .checkbox-sec{display: flex;flex-direction: column;gap: 0.6rem;color:#fff;}
   .checkbox-sec .checkbox-inr{display: flex;align-items: start;}
   .checkbox-sec .custom-checkbox{display: flex;width: 15px;height: 15px;margin: 4px 10px 0 0;}
   .checkbox-sec label{font-size: 0.7rem;}
   .checkbox-sec a{color: #FFDB00;}
   .info{color:#fff;}
    // fill: var(--white-color) !important;
    // filter: invert(.9);
    .sui-button-wrapper{display: flex;
  justify-content: space-between;
  margin: 15px 0;}

  .btn{font-family: "Poppins", sans-serif!important;}
  .btn-flat, .btn-secondary {
    background:#FFDB00!important;
    color: #0058A3!important;
    font-size:15px!important;font-weight: 400!important;display: flex;
    align-items: center;
    gap: 10px;
  }
    
  .btn-flat:hover, .btn-secondary:hover {
    background:#000!important;
    color: white!important;
  }
  .btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }

  .btn-secondary{background:var(--bs-gray-dark);border:0px;}
  .btn-secondary:hover{ background: rgb(var(--bs-danger-rgb));border:0px;}
  .form-control:disabled {
    background-color:#fff;
    opacity: 1;
  }

  .custom-table .t-head{
    background-color: transparent !important;
  }
  
  .custom-table .t-head-item{
    color: #0058A3 !important;
    text-align: center !important;
    background:rgb(255, 219, 0)!important;
  }
  .custom-table-column-one .t-head-item{
    color: white !important;
    text-align: center !important;
    background: #FF0000;
  }
    .custom-table .t-head-item .table-label{font-weight:600;margin-top: 12px;}
    .custom-table-column-one .t-head-item .table-label{font-weight: 600;}


  .custom-table{
    border-collapse: separate !important;
  }
  .custom-table-column-one{
    border-collapse: separate !important;
  }
  .custom-table tr:nth-child(odd) {
    background-color: #013b6c;
    text-align: center;
  }
  .custom-table tr:nth-child(even){
    background-color: #fff;
    text-align: center;
  }

  .custom-table tr:nth-child(even) .main-wrapper>.big-label,.custom-table tr:nth-child(even) td>.table-label{
    color:#0058A3!important;
    font-weight: 500;
    text-align: center;
  }
  .custom-table tr:nth-child(even) .big-label, .custom-table tr:nth-child(even) .table-label{
    // color: #0058A3!important;
  }
  .custom-table-column-one tr:nth-child(odd) {
    background-color: #c1c1c1;
    text-align: center;
  }
  .custom-table-column-one tr:nth-child(even){
    background-color: #f2f2f2;
    text-align: center;
  }
  
  
  // .custom-table td:nth-child(1) {
  //   background-color: transparent !important;
  //   /* color: white !important; */
  // }
  
  .custom-table .t-non-background{
    background-color: #0058A3 !important;
    
  }

  .custom-table-column-one .t-non-background{
    background-color: white !important;
    
  }
  
  .custom-table .table-label{
    font-size: 14px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;
  }

  .custom-table-column-one .table-label{
    font-size: 14px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;
  }

  .d-auto-none{
    display: none !important;
  }
  
  th .text-wrapper{display: flex;flex-direction: column;justify-content: flex-end;text-align: right;padding: 0 6px 0 0;}
  th .text-wrapper .table-label{text-align: right;font-size:16px !important;font-weight: 500;color:#FFFFFF;}
  
  .custom-table .big-label{
  font-size:16px;font-weight:500 !important;line-height: 16px;
  }
  
  .custom-table-column-one .big-label{
    font-size:16px !important;font-weight: 500 !important;
  }
  .buildings-button-wrapper{
    margin-bottom: 12px;
  }
  td .text-wrapper{display: none;}
  .custom-table p{color:#FFFFFF;}
  
  //  .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{height: 1.2em!important;padding: 10px 15px!important;background: #fff !important;border-radius:8px;}
   .css-gyep3o-MuiFormLabel-root-MuiInputLabel-root{font-size: 0.9rem!important;overflow: visible!important;font-family: "Poppins", sans-serif!important;
  color: rgba(0, 0, 0, 1)!important;}
  // -webkit-transition:inherit!important; transition:inherit!important;
  .css-1bwvpd3-MuiFormLabel-root-MuiInputLabel-root{color: rgba(0, 0, 0, 1)!important;background: #fff;padding:1px 5px;border-radius: 4px;font-size: 0.9rem!important;}
  // background-color: rgba(0, 0, 0, 0.8);padding:1px 5px;border-radius: 4px;
 .css-1d3z3hw-MuiOutlinedInput-notchedOutline{border: 0px !important;}
 .css-14lo706{color: #ccc!important;}
 .custom-table tr.last-row:nth-child(2n){background: transparent;}
 .css-1g34nad-MuiInputBase-root-MuiOutlinedInput-root{border-radius:8px!important;}

 .css-85ylo5-MuiSlider-valueLabel{color: #0058A3!important;}
 .css-yb34x9-MuiSlider-rail{background-color: rgb(255,255,255)!important;}
 .css-5ju7a1-MuiSlider-thumb{background-color: rgb(255,255,255)!important;}
 .css-yafthl-MuiSlider-markLabel.MuiSlider-markLabel, .css-1eoe787-MuiSlider-markLabel{color: rgb(255,255,255)!important;}
 ul.arrow-list, .arrow-list-arrow{color: #fff!important;}



      @media (max-width:768px) {
        .first-button-wrapper{flex-direction: column;}
        .voter-box-grid2{flex-wrap: wrap;justify-content: center;}
        .voter-box2{width:30.7%;}

      }
      .buildings-button-wrapper{
        margin-bottom: 12px;
      }



            @media (max-width:576px) {
              .voter-box2 {
                width: 48%;
              }
              .main-wrapper-component{min-height: 680px;}

              .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.1em!important;
                height: 1.1em!important;}
                .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.1em!important;
                  height: 1.1em!important;}

                  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 1.1em!important;
                    height: 1.1em!important;}
      
              .voter-box-grid {
                flex-wrap: wrap;
                justify-content: center;
              }
      
              .map-secstep {
                width: 100%;
                height: 90%;
              }
      
              .big-title {
                font-size: 1rem !important;
                line-height: 1.5rem !important
              }
              .bd-highlight{
                height: 35vh !important;
              }
      
              .small-custom-txt{
                font-size: 14px !important;
              }
              .big-title1{
                font-size: 1rem !important;
                line-height: 1.5rem !important
              }

              .custom-table{
                border-collapse: collapse !important;
              }
              .custom-table tr{
                border: 1px solid #fff;
              }
              .custom-table .table-label{
                font-weight: 500;
              }

              .custom-table td{
                border: 1px solid #ccc;
              }
      
        
      
              .custom-table .table-label {
                font-weight: 700 !important;
              }
      
              .voter-box {
                width: 48%;
              }
              .arrow-list li {
                font-size: 17px;
              }
              @media screen and (max-width:400px) {
                .bd-highlight {
                  // height: 60vh !important;
                }
                .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                  height: 0.8em!important;}
                  .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                    height: 0.8em!important;}
  
                    .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 0.8em!important;
                      height: 0.8em!important;}

                     .margin-small-0{
                       margin: 0 !important;
                     }
                     .big-title{
                       margin: 6px !important;
                     }

      
              }
      
              .bonne-sec .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .bonne-sec .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              .contacte-form .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .contacte-form .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              // .table-wrapper{overflow: hidden;overflow-x: scroll;}
              // .custom-table {width: 570px;}
      
              .custom-table th {
                display: none;
              }


              .custom-table tr:not(.d-auto-none) {
               background-color: #fff !important;
              }
              .custom-table tr:not(.d-auto-none) .big-label,  .custom-table tr:not(.d-auto-none) .table-label{
                color: #0058A3!important;
              }
              .custom-table .d-auto-none{
                display: table-row !important;
                background-color: #013b6c !important;
                text-align: center;
                color:#fff;
              }
              .custom-table .d-auto-none .table-label, .custom-table tr:nth-child(2n) .table-label {
                // color: #fff !important;
              }
              .custom-table tr:nth-child(2n) .table-label.text-danger {
                color:#0058A3 !important;
              }
            
              .custom-table tr:not(.d-auto-none) p{  color: #0058A3!important;}
              .custom-table td {
                padding-top: 6px;
                padding-bottom: 6px;
              }
              .buildings-button-wrapper{
                margin-bottom: 4px;
              }
      
              td .text-wrapper {
                display: flex;
                flex-direction: column;
              }
              .custom-table tr.last-row:nth-child(2n) {
                background: #013b6c !important;
              }
              .custom-table tr.last-row:nth-child(2n) p{color: #fff!important;}
              .css-gyep3o-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 0.7rem !important;white-space:wrap !important;}
                .btn-flat, .btn-secondary{width: 100%;text-align: center;justify-content: center;}
                .main-wrapper-component{min-height: 600px;overflow-y: scroll;height: 600px;}
                .custom-table{display: table; table-layout: fixed;}

            }

    .info{
      font-size: 12px;
    }

      @media (max-width:475px) {
        .sui-button-wrapper{flex-direction: column;}
        .sui-button-wrapper .button-wrapper{margin: 5px 0;}
        .sui-button-wrapper .button-wrapper button{width: 100%;}
      
        .voter-box2{width:47%;}
        .voter-box{width:47%;}
      


        .inputbox::placeholder{
          font-size: 11.5px
        }}

        .custom-checkbox{
         /*  accent-color: rgb(var(--bs-danger-rgb)) !important;Red background color when checked */
          accent-color:#FFDB00!important;
          border-color: rbb(var(--bs-danger-rgb) !important); /* Red border color when checked */
        }
        /* AutoCompleteMain.scss */

        .autosuggest-container {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;
        }
        
        .suggestions-container-open {
          position: absolute;
          z-index: 1000;
          width: 100%;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          margin-top: 2px;  /* Remove margin */
          padding: 0; /* Remove padding */
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          ul{
            margin: 0px;

            padding: 0px;
          }
        }
        
        .suggestion {
          padding: 10px 15px;  /* Reduced padding */
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        .suggestion-highlighted {
          background-color: #f1f1f1;
        }
        
        .stepper {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          
          .stepper-step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            cursor: pointer;
            
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 100%;
              width:390%;
              border-top: 2px dashed #A6ADB5;
             z-index: 0;
            }
            
            &:last-child::after {
              display: none;
            }
            

          }
         .stepper-step::before{
          content: '';
          position: absolute;
          top:-6px;
          left:-3px;
          width:68px;
          height: 71px;

         }
          .stepper-step.active .stepper-label {
            font-weight: 600;
            color:#0058AB;
            background-color:#fff;border-color:#A6D7DD;

          }
        
          .stepper-step.active::after {
            background-color:#0058AB ;
          }

          .stepper-label {
            width:59px;
            height: 59px;
            padding:0px ;font-size: 35px;line-height:58px;text-align: center;font-weight: 600;color:#fff;
            border-radius:100px;position: relative;
            z-index: 1;
            border:2px solid #A6D7DD;
            background-color:#0058AB ;
          }
        }
        
        .custom-loader {
          width:140px;
          height:141px;
          color: #4E73DF;
          background:url(../assets/image/loader.png) no-repeat 0 0;
          animation: sh7 1.5s infinite linear;
      }
      .stepper .stepper-label i{display: none;}
      .stepper-step.active .stepper-label i{display: inline-block;font-style: normal;}
      .stepper .stepper-label span{display: inline-block;}
      
      @keyframes sh7 {
          100% {
              transform: rotate(1turn)
          }
      }

      @media (max-width:992px) {
      .stepper .stepper-step::after {width: 335%;}
      }
      @media (max-width:768px) {
        .stepper .stepper-step::after {width:235%;}
        .css-li58zp-MuiFormLabel-root-MuiInputLabel-root{white-space: wrap!important;font-size: 0.8rem !important;line-height: 1.18em!important;}
        // .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        //   height: 1.8em !important;}
          .css-yafthl-MuiSlider-markLabel.MuiSlider-markLabel, .css-1eoe787-MuiSlider-markLabel{color: rgb(255,255,255)!important;}
        }
        @media (max-width:590px) {
          .stepper .stepper-step::after {width:190%;}
          .my-custom-input-color-suggestion .MuiInputBase-root{
            height: 10vh !important;
          }
          }
          @media (max-width:520px) {
            .stepper .stepper-step::after {width:300%;}
            .main-wrapper-component {
              padding: 15px;
            }
            .stepper .stepper-label {
              width:34px;
              height: 34px;font-size: 20px;
              line-height: 30px;
            }
            .stepper .stepper-step::before{
              top:-3px;
              left:-2px;
              width:39px;
              height:40px;
              background:url(../assets/image/step-border-mob.svg) no-repeat 0 0;
             }
             .css-yafthl-MuiSlider-markLabel.MuiSlider-markLabel, .css-1eoe787-MuiSlider-markLabel{color: rgb(255,255,255)!important;}

          }


          @media (max-width:420px) {
            .stepper .stepper-step::after {width:247%;}

          }
          @media (max-width:401px) {
          // .main-wrapper-component{height: 116vh;}
          }

          .economic-txt{
            font-size: 12px;
            color: #ffffff;
          }

          .autosuggest-container {
            border-radius: 10px !important;
            background: #0058AB !important;
        }


          .my-custom-input-color-suggestion .MuiInputLabel-root{
            color: #fff !important;
            background-color: #0058AB !important;
          }

          .my-custom-input-color-suggestion .MuiInputBase-root{
            color: #fff !important;
          }
 



          .suggestions-container-open{
            margin-top: 4px !important;
          }

          .my-custom-input-color-suggestion fieldset{
            border: 2px solid #fff !important;
          }
          .my-custom-input-color-suggestion .MuiInputLabel-root{
            font-size: 16px !important;
          }